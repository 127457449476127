<template>
  <div class="pre-mint">
    <div class="pre-mint__banner">
      <img
        class="pre-mint__banner-img"
        src="../assets/img/form_ape.jpeg"
        alt="ape-event"
      >
      <div class="pre-mint__banner-overlay" />
    </div>
    <div class="pre-mint__content">
      <div class="pre-mint__info">
        <h1>THE APE INVASION HAS BEGUN!!</h1>

        <div class="pre-mint__info-details">
          <p>Sold Out!</p>
        </div>
      </div>

      <!--<div class="pre-mint__info">
        <h2>This Mint will likely sell out before Public Mint opens.</h2>

        <div class="pre-mint__info-details">
          <a
            href="https://bullsapes.typeform.com/AllowList"
            target="_blank"
            rel="noopener noreferrer"
            class="pre-mint__info-link"
          >Apply for the Allow List Now</a>
        </div>
      </div>-->

      <!-- <div class="pre-mint__info">
        <h2>Are you eligible for the Whale List?</h2>

        <div class="pre-mint__info-details">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bullsapes.typeform.com/WhaleList"
            class="pre-mint__info-link"
            >Apply here and find out!</a
          >
        </div>
      </div> -->

      <div class="pre-mint__info">
        <h3 class="pre-mint__info-title">
          Allow List Details
        </h3>

        <div class="pre-mint__boxes">
          <div
            v-for="tier in tiers"
            :key="tier.name"
            class="pre-mint__box outlined"
          >
            <div class="pre-mint__box-header">
              <h4 class="pre-mint__box-title">
                {{ tier.title }}
              </h4>
              <p class="pre-mint__box-price">
                {{ tier.price.toFixed(2) }} ETH
              </p>
            </div>
            <p class="pre-mint__box-description">
              {{ tier.description }}
            </p>
          </div>
          <!-- <div class="pre-mint__box warning">
            <h4 class="pre-mint__box-title">**UPDATE**</h4>

            <p class="pre-mint__box-description">
              Ape Allow List Tiers have been announced... Tier 1 is LOCKED!
            </p>
            <p class="pre-mint__box-description">
              It is HIGHLY LIKELY we sell out before reaching Tier 3
            </p>
            <p class="pre-mint__box-description bold">
              Don't Miss Your Chance to Own an Ape
            </p>
            <p class="pre-mint__box-description bold">
              Buy an OG Bull to Qualify For Tier 2!
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://opensea.io/collection/bullsandapes-genesis"
            >
              I NEED TO GET IN TIERS 2!
            </a>
          </div> -->
        </div>
      </div>

      <div class="pre-mint__faqs">
        <h2 class="pre-mint__faqs-title">
          Frequently Asked Questions
        </h2>
        <div class="pre-mint__faqs-container">
          <h3 class="pre-mint__faqs-subtitle">
            Mint Questions
          </h3>

          <button
            v-for="faq in faqs.mint"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Ape Questions
          </h3>

          <button
            v-for="faq in faqs.apes"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Dynamic NFT & Traits Questions
          </h3>

          <button
            v-for="faq in faqs.nfts"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>
        </div>
      </div>

      <div class="pre-mint__content-overlay" />
    </div>
  </div>
</template>

<script>
import PAGE_DATA from "../config/pre_mint_data.json";
import { Countdown } from "vue3-flip-countdown";

const { faqs, page_data, tiers } = PAGE_DATA;

const mint = faqs.mint.map((faq) => ({
  ...faq,
  open: false,
  active: false,
}));

const apes = faqs.apes.map((faq) => ({
  ...faq,
  open: false,
}));

const nfts = faqs.nfts.map((faq) => ({
  ...faq,
  open: false,
}));

export default {
  name: "PreMint",
  components: {
    Countdown,
  },
  props: {
    handleBuyTickets: {
      type: Function,
    },
  },
  data() {
    return {
      faqs: {
        mint,
        apes,
        nfts,
      },
      pre: page_data.pre,
      fast: page_data.fast,
      main: page_data.main,
      tiers,
    };
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.page-wrapper {
  padding-top: 0 !important;
}

body {
  background: #0f0612;
}

.BAP-header {
  display: none;
}

.header-announcement {
  display: none !important;
}

@media screen and (min-width: 1150px) {
  .BAP-header {
    background: transparent !important;
    justify-content: center !important;
  }

  .BAP-header__logo {
    display: none;
  }

  .BAP-header__socials {
    display: none;
  }

  .BAP-header__nav {
    padding: 0.5rem;
    background: hsla(291, 48%, 11%, 0.3);
    border-radius: 0.5rem;
    /* background filter blur */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
}

.start-page-news-letter {
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  background: #28323e71;
  width: min(calc(100% - 8rem), 500px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: span 2;
}

.start-page-news-letter-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.start-page-news-letter input {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.start-page-news-letter button {
  background: #ab1efe;
  border: 1px solid #ab1efe;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.start-page-news-letter-title {
  /* display: none; */
}

.start-page-news-letter-bg {
  /* background: none; */
  background: url("../assets/img/Bulls/ApeMale12_Main.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: color;
}

@media screen and (max-width: 768px) {
  .start-page-news-letter {
    width: calc(100% - 4rem);
    height: auto;
  }
  .start-page-news-letter {
    grid-column: unset;
  }
}

.footer {
  margin: 0;
}

.flip-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .flip-clock {
    gap: 0.75rem;
  }
}
</style>
